import { useAppData } from "src/context/useAppData"
import { canViewExpiringHomeTokens } from "src/data/homes/logic/homeTokenUtil"
import {
  useFetchExpiringHomeTokens,
  useFetchUserExpiringHomeTokens,
} from "src/data/homes/queries/homeTokenQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useHomeTokens } from "src/hooks/useHomeTokens"

type TBaseExpiringHomeTokens = {
  /**
   * There are expiring home tokens.
   *
   * If the user is not an admin, the subscription is not cancelled or the subscription is not plan_unit_per_home, this will be false.
   */
  hasExpiringHomeTokens: boolean
}

type TExpiringHomeTokens = TBaseExpiringHomeTokens &
  (
    | {
        hasExpiringHomeTokens: true
        expiringHomeTokensCount: number
        earliestExpiringHomeToken: string
      }
    | {
        hasExpiringHomeTokens: false
        expiringHomeTokensCount?: number
        earliestExpiringHomeToken?: string
      }
  )

export function useOrgExpiringHomeTokens(): TExpiringHomeTokens {
  const { org, orgAccessLogic } = useOrganization()

  const fetchOrgExpiringHomeTokens = useFetchExpiringHomeTokens({
    orgId: org.id,
  })

  const expiringHomeTokens = fetchOrgExpiringHomeTokens.data?.home_tokens || []
  const expiringHomeTokensCount = expiringHomeTokens.length
  const earliestExpiringHomeToken = expiringHomeTokens[0]

  const isCancelled = org.current_subscription_status === "cancelled"

  const hasExpiringHomeTokens =
    canViewExpiringHomeTokens({
      expiringHomeTokens: expiringHomeTokensCount,
      earliestExpiringHomeToken: earliestExpiringHomeToken?.expires_at,
      billingModel: org.billing_model,
      userOrgAccessLevel: orgAccessLogic,
      isCancelled,
    }) && !!earliestExpiringHomeToken

  if (hasExpiringHomeTokens) {
    return {
      hasExpiringHomeTokens,
      expiringHomeTokensCount,
      earliestExpiringHomeToken: earliestExpiringHomeToken.expires_at,
    }
  }

  return {
    hasExpiringHomeTokens,
  }
}

export function useUserExpiringHomeTokens(): TExpiringHomeTokens {
  const user = useGetUser()
  const { hasHomeTokenRole } = useHomeTokens()
  const { currentSubscription } = useAppData()
  const { orgAccessLogic } = useOrganization()

  const fetchUserExpiringHomeTokens = useFetchUserExpiringHomeTokens({
    userId: user.user_id,
    options: { enabled: hasHomeTokenRole },
  })

  const expiringHomeTokens = fetchUserExpiringHomeTokens.data?.home_tokens || []
  const expiringHomeTokensCount = expiringHomeTokens.length
  const earliestExpiringHomeToken = expiringHomeTokens[0]

  const isCancelled = currentSubscription?.subscription_status === "cancelled"

  const hasExpiringHomeTokens =
    canViewExpiringHomeTokens({
      expiringHomeTokens: expiringHomeTokensCount,
      earliestExpiringHomeToken: earliestExpiringHomeToken?.expires_at,
      billingModel: currentSubscription?.billing_model,
      userOrgAccessLevel: orgAccessLogic,
      isCancelled,
    }) && !!earliestExpiringHomeToken

  if (hasExpiringHomeTokens) {
    return {
      hasExpiringHomeTokens,
      expiringHomeTokensCount,
      earliestExpiringHomeToken: earliestExpiringHomeToken.expires_at,
    }
  }

  return {
    hasExpiringHomeTokens,
  }
}
