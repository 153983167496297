import { components } from "@minuthq/minut-api-types/schema"

import { getAccessLogic } from "src/data/user/logic/accessLogic"

export function canViewExpiringHomeTokens({
  expiringHomeTokens,
  earliestExpiringHomeToken,
  billingModel,
  userOrgAccessLevel,
  isCancelled,
}: {
  expiringHomeTokens: number
  earliestExpiringHomeToken?: string
  billingModel: components["schemas"]["BillingModel"] | undefined
  userOrgAccessLevel: ReturnType<typeof getAccessLogic>
  isCancelled: boolean
}) {
  if (
    userOrgAccessLevel.hasAdminAccess &&
    expiringHomeTokens > 0 &&
    !!earliestExpiringHomeToken &&
    billingModel === "plan_unit_per_home" &&
    !isCancelled
  ) {
    return true
  }

  return false
}
